import React from 'react';
import Router from "../Router";

const Main = () => {
    return (
        <React.Fragment>
             <Router/>
        </React.Fragment>
    );
};

export default Main;